<template>
  <v-card>
    <LoaderThreeBody absolute :loading="loading" />
    <v-card-title>
      <h3>{{ category === null ? $t('newCategory') : $t('editCategory') }}</h3>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid" ref="form" class="pb-4 pt-4">
        <v-text-field
          v-model="form.name"
          :label="$t('name')"
          required
          dense
          :rules="[isRequired]"
        />
        <v-textarea
          v-model="form.description"
          :label="$t('description')"
          rows="2"
          dense
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="grey lighten-1"
        small
        @click="cancel"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        small
        @click="saveCategory"
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CategoryServices from '@/store/services/general/CategoryServices'
import { mapGetters } from 'vuex';
export default {
  props: {
    category: {
      default: null
    }
  },
  data() {
    return {
      loading: false,
      valid: true,
      form: {
        id: null,
        name: '',
        description: ''
      },
  
      isRequired: value => !!value || this.$t('requiredField'),
    };
  },
  methods: {
    async saveCategory() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          var response = ''
          if (!this.form.id || this.form.id === '') {
            let dataForm = new FormData()
            for (let key in this.form) {
              dataForm.append(key, this.form[key])
            }
            response = await CategoryServices.store(this.user.archon.id, dataForm)
          } else {
            response = await CategoryServices.update(this.user.archon.id, this.form.id, this.form)
          }
          this.loading = false
          this.resetFrom()
          this.$emit('onCategorySaved', {
            category: response.data,
          });
          this.$emit('onCancel')
        } catch (e) {
          this.loading = false
          console.log(e)
        }
      }
    },
    resetFrom() {
      this.form.id = null
      this.form.name = ''
      this.form.description = ''
    },
    setForm() {
      if (this.category) {
        this.form.id    = this.category.id
        this.form.name  = this.category.name
        this.form.description  = this.category.description
      }
    },
    cancel() {
      this.resetFrom()
      this.$emit('onCancel')
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    category() {
      this.setForm()
    }
  }
}
</script>

<style>

</style>