<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <CategoryList
          :createButton="false"
          ref="categoryList"
          @onEditCategory="editCategory"
        />
      </v-col>
      <v-col cols="12" md="6">
        <CategoryForm
          :category="category"
          @onCategorySaved="categorySaved"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CategoryList from '@/components/category/CategoryList'
import CategoryForm from '../../components/category/CategoryForm'
export default {
  components: {
    CategoryList,
    CategoryForm
  },
  data: () => ({
    category: null
  }),
  methods: {
    categorySaved() {
      this.$refs.categoryList.resetCurrentPage()
    },
    editCategory(category) {
      this.category = category
    }
  }
}
</script>

<style>

</style>