<template>
  <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-space-between">
      <div style="width: 150px;">
        <v-select
          v-model="perPage"
          label="Total visible"
          :items="listPerPage"
          @change="resetCurrentPage"
          dense
        />
      </div>
      <div>
        <v-btn
          @click="getCategories"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t('refresh') }}
        </v-btn>
        <v-btn
          v-if="createButton"
          @click="createCategory"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('category') }}
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="categories"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              @click="editCategory(item)"
            >
              <v-icon left>mdi-pencil</v-icon>
              <small>{{ $t('edit') }}</small>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
  </div>
</template>

<script>
import CategoryServices from '@/store/services/general/CategoryServices'
import ListMixin from '@/mixins/commons/ListMixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [ListMixin],
  props: {
    createButton: {
      default: true
    }
  },
  data: () => ({
    loading: false,
    categories: [],

  }),
  methods: {
    async getCategories()
    {
      try {
        const params = {
          page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
        };
        this.loading = true;
        const response = await CategoryServices.index(this.user.archon.id, params);
        this.categories = response.data.data;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.total = response.data.total;
        
        this.loading = false;
      } catch (e) {
        console.log(e)
      }
    },
    resetCurrentPage() {
      if (this.currentPage === 1) this.getCategories();
      else this.currentPage = 1;
    },
    createCategory() {
      this.$emit('onCreateCategory')
    },
    editCategory(category) {
      this.$emit('onEditCategory', category)
    }
  },
  created() {
    this.getCategories()
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: this.$t('name'),
          value: 'name',
        },
        { text: this.$t('description'), value: 'description', sortable: false },
      ];
    },
  }
}
</script>

<style>

</style>