import ApiService from '../Api';

class StatusServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/categories`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/categories`, body);
  }

  update(archon, category, body) {
    return this.api.put(`${this.resource}/${archon}/categories/${category}`, body);
  }
}

export default new StatusServices();